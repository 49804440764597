<template>
	<div class="about-main">
		<div class="title-img">
			<div class="title-img-wapper">
				<h2>诚信，专业，帮助他人伟大</h2>
			</div>
		</div>
		<div class="main1">
			<h1>远舟概况</h1>
			<div class="content">
				<p>
					远舟集团是一家从2000年就开始从事医用红外影像技术专业研发、生产和销售的高科技集团化企业，拥有杭州远舟医疗科技有限公司，重庆远舟医疗科技有限公司，重庆飞洲光电技术研究院等多家经营实体。集团拥有70多项自主知识产权，是国家高新技术企业，浙江省健康产业优秀企业，杭州市雏鹰计划企业。
				</p>
				<p>
					远舟集团是国内医用红外检测应用技术的创新引领者，集团与以国家超算中心牵头的十多所重点科研院校和行业专业协会及众多医学专家建立了良好的战略合作伙伴关系，强强联手，共同搭建了产学研及临床相融合的研究平台，在行业内首创研发出医用红外AI检测平台，并开创了大健康产业智慧经营创新模式的先河。
				</p>
				<p>
					远舟集团认真贯彻和践行“健康中国2030规划纲要“，以核心技术和智慧经营模式构建医养大健康产业互联生态，让红外的健康幸福之光普照中国大地。
				</p>
			</div>
			<el-image :src='require("@/assets/picture/公司2.png")' lazy></el-image>
		</div>
		
		<div class="main2">
			<h1>发展历程</h1>
			<p class="main-desc">20年砥砺前行，20年与时俱进，每一步都引领行业迈向更高、更好的境界。现在，我们厚积薄发，开创未来。</p>
			<div class="content">
				<div class="shuxian"></div>
				<ul>
					<li v-for="item,index in jinlis" :key="index">
						<dislog :data='item' :flag='index%2===0?true:false' />
					</li>
				</ul>
			</div>
		</div>
		
		<div class="main3">
			<h1>团队成员</h1>
			<p class="main-desc">核心团队均由各自领域的极富专业背景、能力极强且有长期从业经验的资深人士和专家学者担纲</p>
			<div class="content">
				<people v-for="item,index in teams"
					:key="index" :item="item"></people>
			</div>
		</div>
		
		<div class="main4">
			<h1>企业文化</h1>
			<div class="content">
				<el-progress :percentage="percentage" color="#909399"></el-progress>
				<div class="swiper-container">
				    <div class="swiper-wrapper">
						<div class="swiper-slide">
							<div class="swcontent">
								诚信、专业、帮助他人伟大
								<div class="jian"></div>
							</div>
							<p class="swtitle">价值观</p>
						</div>
						<div class="swiper-slide">
							<div class="swcontent">
								引领和推动红外在医疗健康影像领域创新应用
								<div class="jian"></div>
							</div>
							<p class="swtitle">企业使命</p>
						</div>
						<div class="swiper-slide">
							<div class="swcontent">
								人人都享有最精准的优质健康服务
								<div class="jian"></div>
							</div>
							<p class="swtitle">企业愿景</p>
						</div>
						<div class="swiper-slide">
							<div class="swcontent">
								用户至上、用心服务
								<div class="jian"></div>
							</div>
							<p class="swtitle">服务理念</p>
						</div>
				    </div>
				    <!-- 如果需要导航按钮 -->
				    <div class="swiper-button-prev"></div>
				    <div class="swiper-button-next"></div>
				</div>
			</div>
		</div>
		<div class="main5">
			<h1>资质荣誉</h1>
			<p class="main-desc">拥有70多项自主知识产权，连续获得国家高新技术企业，浙江省健康产业优秀企业，杭州市雏鹰计划企业等数十项殊荣与奖励。</p>
			<div class="content">
				<p class="title5">主要专利证书</p>
				<div class="zhuanli">
					<div>
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/专利1.png")' ></el-image>
						<p>发明</p>
						<p>提取目标图像的方法及装置</p>
					</div>
					<div>
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/专利2.png")' ></el-image>
						<p>实用新型专利</p>
						<p>一种医用红外设备</p>
					</div>
					<div>
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/专利3.png")' ></el-image>
						<p>软件著作</p>
						<p>远舟红外热成像映射到对象的面积计算系统</p>
					</div>
					<div>
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/专利4.png")' ></el-image>
						<p>外观</p>
						<p>一体式智能医用红外设备</p>
					</div>
				</div>
				
				<p class="title5">主要资质荣誉</p>
				<div class="rongyu">
					<div>
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/认证1.jpg")' ></el-image>
						<p>认证</p>
						<p>01-ISO13485认证</p>
					</div>
					<div>
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/认证2.png")' ></el-image>
						<p>认证</p>
						<p>国家高新技术企业</p>
					</div>
					<div>
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/认证3.png")' ></el-image>
						<p>认证</p>
						<p>浙江省高成长科技型中小企业</p>
					</div>
					<div>
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/认证4.jpg")' ></el-image>
						<p>荣誉</p>
						<p>美年大健康年度集团供应商</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const jinli=[
		{time: '2000/3',thing: '前身上海维恩伟业红外医疗器械有限公司创建'},
		{time: '2008/10',thing: '旗下重庆飞洲光电技术研究院成立'},
		{time: '2010/4',thing: '开始参与国家自然基金科研等一系列重大课题'},
		{time: '2014/10',thing: '获得国际医疗器械质量管理体系ISO13485认证'},
		{time: '2016/5',thing: '完成A轮融资'},
		{time: '2016/11',thing: '与国医大师王琦教授共同开展《中医九种体质辨识与红外技术临床应用课题研究》项目'},
		{time: '2016/12',thing: '成立”健康中国“红外读图师联盟'},
		{time: '2017/3',thing: '与北京韩济生疼痛研究院开展关于疼痛大数据统计研究与中南大学开展关于疼痛6中分类方法的研究'},
		{time: '2017/5',thing: '红外影像云平台正式上线，自动出具报告'},
		{time: '2017/8',thing: '移动式智能终端研发成功，正式进入基层医疗'},
		{time: '2017/11',thing: '荣获“国家高新技术企业”荣誉称号'},
		{time: '2019/12',thing: '面向基层医疗机构的小胖哥正式上市，备受市场青睐'},
		{time: '2020/1',thing: '逆行投入新型冠状病毒肺炎抗疫行动'},
		{time: '2020/6',thing: '大健康产业全新商业模式落地及平台上线'},
		{time: '2020/12',thing: '面向基层医疗及大健康产业红外设备研发成功'}
	]
	const teams=[
		{
			img: '团队成员-董.jpg',
			name: '董海舟',
			desc: '远舟集团创始人，董事长'
		},
		{
			img: '娄强.png',
			name: '娄强',
			desc: '首席疼痛康复专家'
		},
		{
			img: '杨俊杰照片.png',
			name: '杨俊杰',
			desc: '高级临床医学顾问'
		},
		{
			img: '团队成员-曹东兴.jpg',
			name: '曹东兴',
			desc: '集团副总裁-主管智能硬件研发'
		},
		{
			img: '团队成员-李宝文.jpg',
			name: '李宝文',
			desc: '集团副总裁-主管市场销售'
		},
		{
			img: '团队成员-郭迪伟.jpg',
			name: '郭迪伟',
			desc: '集团副总裁-主管生产制造'
		},
		{
			img: '团队成员-刘涛.jpg',
			name: '刘涛',
			desc: '集团副总裁-主管公司运营'
		},
		{
			img: '团队成员-蒋凤娓.jpg',
			name: '蒋凤娓',
			desc: '集团副总裁-主管平台运营'
		}
	]
	import Dislog from '@/components/dislog/index'
	import People from '@/components/people/index'
	import Swiper from 'swiper'
	
	export default {
		name: 'About',
		components:{Dislog,People,Swiper},
		
		data(){
			return{
				jinlis: [],
				teams: [],
				percentage: 25
			}
		},
		methods:{
			changePercentage(val){
				const i=val % 5
				switch (i){
					case 0:
					case 1:
						this.percentage=25;
						break;
					case 2:
						this.percentage=50;
						break;
					case 3:
						this.percentage=75;
						break;
					case 4:
						this.percentage=100;
						break;
				}
			}
		},
		mounted() {
			this.jinlis=jinli
			this.teams=teams
			const that=this
			new Swiper ('.swiper-container', {
			    loop: true,
			    // 如果需要前进后退按钮
			    nextButton: '.swiper-button-next',
			    prevButton: '.swiper-button-prev',
			     //如果需要自动切换海报
			    autoplay: 3000,
				autoplayDisableOnInteraction: false,
				onSlideChangeStart: function(swiper){
					that.changePercentage(swiper.activeIndex)
				},
			})
		}
	}
</script>

<style lang="scss">
	@import './index.scss';
	@import '~@/styles/public.scss';
</style>
