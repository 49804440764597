<template>
	<div class="pemain">
		<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/"+item.img)'  />
		<p class="name">{{item.name}}</p>
		<p class="desc">{{item.desc}}</p>
	</div>
</template>

<script>
	export default {
		name: 'People',
		props:{
			item:{
				type: Object,
				required: true
			}
		}
	}
</script>

<style lang="scss">
	.pemain{
		background-color: #fff;
		width: 20%;
		border: 5px #fff solid;
		text-align: center;
		
		&>img{
			width: 100%;
		}
		
		.name{
			font-family: "Poppins",Sans-serif;
			font-size: 18px;
			font-weight: 500;
			text-transform: none;
			font-style: normal;
			text-decoration: none;
			line-height: 28px;
			letter-spacing: 0px;
			margin: 0;
		}
		
		.desc{
			margin: 0;
			margin-bottom: 10px;
			color: #393939;
			font-family: "Poppins",Sans-serif;
			font-size: 13px;
			font-weight: 400;
			text-transform: none;
			font-style: normal;
			text-decoration: none;
			line-height: 27px;
			letter-spacing: 0px;
		}
	}
</style>
