<template>
	<div class="dimain">
		<div class="rad"></div>
		<div class="dicontent" :class="{'left':!flag}">
			<div :class="{'leftjiao':flag,'rightjiao':!flag}">
				<div :class="{'leftjiao2':flag,'rightjiao2':!flag}"></div>
			</div>
			<p class="time">{{mydata.time}}</p>
			<p class="thing">{{mydata.thing}}</p>
		</div>
		
	</div>
</template>

<script>
	export default {
		name: 'Dislog',
		props:{
			data: {
				type: Object,
				required: true
			},
			flag: {
				type: Boolean,
				required: true
			}
		},
		computed:{
			mydata(){
				return this.data
			}
		},
	}
</script>

<style lang="scss">
	.left{
		float: left !important;
	}
	.dimain{
		width: 100%;
		background: #fff;
		position: relative;
		margin-bottom: 20px;
		overflow: hidden;
		.rad{
			width: 15px;
			height: 15px;
			background-color: #2196f3;
			border-radius: 50%;
			position: absolute;
			top: calc(50% - 10px);
			left: calc(50% - 10px);
			border: 5px solid #d2e3f9;
			border-radius: 50%;
			z-index: 3;
		}
		.leftjiao{
			width: 3px;
			height: 3px;
			position: absolute;
			top: calc(50% - 10px);
			left: -23px;
			border-right: 20px rgba(0,0,0,.15) solid;
			border-top: 10px transparent solid;
			border-bottom: 10px transparent solid;
		}
		.leftjiao2{
			width: 0px;
			height: 0px;
			position: absolute;
			top: calc(50% - 10px);
			left: 3px;
			border-right: 20px #fff solid;
			border-top: 8px transparent solid;
			border-bottom: 8px transparent solid;
		}
		.rightjiao{
			width: 3px;
			height: 3px;
			position: absolute;
			top: calc(50% - 10px);
			right: -23px;
			border-left: 20px rgba(0,0,0,.15) solid;
			border-top: 10px transparent solid;
			border-bottom: 10px transparent solid;
		}
		.rightjiao2{
			width: 0px;
			height: 0px;
			position: absolute;
			top: calc(50% - 10px);
			right: 3px;
			border-left: 20px #fff solid;
			border-top: 8px transparent solid;
			border-bottom: 8px transparent solid;
		}
		.dicontent{
			position: relative;
			float: right;
			width: 45%;
			border: 1px solid #d2e3f9;
			box-shadow: 0 1px 3px rgba(0,0,0,.15);
			padding: 15px;
			box-sizing: border-box;
			border-radius: 5px;
			p{
				margin: 0;
				color: #333;
			}
			.time{
				line-height: 1.4em;
				letter-spacing: 0px;
				font-family: "Libre Baskerville","Arial","Helvetica","sans-serif";
				font-size: 18px;
				font-weight: 600;
			}
			.thing{
				margin: 8px 0;
				font-size: 15px;
			}
		}
	}
	
	@media all and (max-width: 750px){
		.left{
			float: right !important;
		}
		.dicontent{
			width: 90% !important;
		}
		.rightjiao{
			left: -23px !important;
			border-left: none !important;
			border-right: 20px rgba(0,0,0,.15) solid;
		}
		.rightjiao2{
			left: 3px !important;
			border-left: none !important;
			border-right: 20px #fff solid;
		}
		.rad{
			left: 0 !important;
		}
	}
	@media  all and (max-width: 576px) {
		.dicontent{
			width: 80% !important;
		}
	}
</style>
